@import "../../_globalColor";

.social-media-div {
  font-size: 2em;
}

.icon-button {
  margin-bottom: 10px;
}

.icon-button i {
  color: $textColorDark;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
  transition: 0.2s ease-in;
}

.facebook i {
  background-color: $faceBook;
}

.linkedin i {
  background-color: $linkedin;
}

.github i {
  background-color: $github;
}

.goodreads i {
  background-color: $goodreads;
}

.google i {
  background-color: $google;
}

/* Hover Transition */
.google i:hover,
.github i:hover,
.linkedin i:hover,
.goodreads i:hover
{
  background-color: $textColor;
}

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
  }
}
